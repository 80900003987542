import { Grid, Link as MaterialLink, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    padding: "100px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    background:
      "linear-gradient(90deg, rgba(0,73,148,1) 1%, rgba(0,113,188,1) 50%, rgba(6,175,214,1) 100%), radial-gradient(circle at 5px 5px,#2f4e7940 5px,transparent 5px,transparent 8px)0 0 / 8px 8px",

    backgroundBlendMode: "multiply, normal, normal, normal",
  },
  wrapper: {
    flexDirection: "column !important",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  btn: {
    marginTop: "15px !important",
    background: "#FFFFFF !important",
    borderTopLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    color: "#0162AD !important",
    padding: "10px 20px !important",
    zIndex: "9999",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "uppercase",
  },
}));

const HomeFullItem = ({ from = "home", title, subtitle }) => {
  const classes = useStyles();
  const isMobile = useMobileHook();
  console.log("from", from);
  return (
    <Grid className={classes.root}>
      {from !== "contactUs" ? (
        <Grid className={classes.wrapper}>
          {!isMobile ? (
            <>
              <Typography variant="h3" color="#fff">
                Are you ready for a new
              </Typography>
              <Typography variant="h3" color="#fff">
                experience in Latam?
              </Typography>
            </>
          ) : (
            <Typography
              variant="h3"
              fontSize={"32px"}
              color="#fff"
              textAlign={"center"}
            >
              Are you ready for a new experience in Latam?
            </Typography>
          )}
          <MaterialLink
            className={classes.btn}
            component={Link}
            underline="none"
            to="/contact-us"
          >
            Contact Us
          </MaterialLink>
        </Grid>
      ) : (
        <Grid className={classes.wrapper}>
          <Typography
            variant="h3"
            fontSize={isMobile && "32px"}
            color="#fff"
            marginBottom={"10px"}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            fontSize={isMobile && "16px"}
            textAlign={isMobile && "center"}
            color="#fff"
          >
            <a
              style={{ color: "#fff", textDecorationLine: "none" }}
              href="mailto:support@rixsus.com"
            >
              {subtitle}
            </a>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default HomeFullItem;
