import {
  Button,
  Container,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "../Card";
import Navbar from "../Navbar";
import mainImage from "../../img/contactUsBg.png";
import Header from "../Header";
import Particles from "react-particles-js";
import { useEffect, useRef, useState } from "react";
import HomeFullItem from "../HomeFullItem";
import Footer from "../Footer";
import SemiCard from "../SemiCard";
import useMobileHook from "../Hooks";
import emailjs from "emailjs-com";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: `url(${mainImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${mainImage})`,
      backgroundColor: "transparent",
      backgroundSize: "initial",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  cardWrapper: {
    position: "absolute",
    margin: "-50px -150px",
  },
  formWrapper: {
    marginTop: "180px",
    marginBottom: "180px",
    padding: "75px 90px   ",
    background: "#fff",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    boxShadow: "0px 0px 44px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("sm")]: {
      padding: "75px 45px",
      marginTop: "90px",
    },
  },
  inputField: {
    padding: "15px 0 !important",
    width: "100%",
    zIndex: "100000",
    "& label": {
      color: "#505050",
    },
  },
  textArea: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #000",
    outline: "none",
    fontFamily: "rubik",
    "& hover": {
      border: "none !important",
    },
  },
  btn: {
    border: "1px solid #00B1D9 !important   ",
    borderTopLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    backgroundColor: "#00B1D9 !important",
    padding: "10px !important",
    color: "#fff !important",
    width: "120px",
    zIndex: "99999",
  },
  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "9999999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
}));
const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2jk5g7d",
        "template_1s2s54s",
        form.current,
        "user_G04CqjBkUJKPWvYDJzrmr"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  const classes = useStyles();
  const [showParticles, setShowParticles] = useState(false);
  const isMobile = useMobileHook();

  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    console.log("Scrolling...", window.scrollY);
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);
    setTimeout(() => {
      setShowParticles(true);
    }, 500);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <div>
        <Container
          container
          maxWidth="xl"
          style={{
            height: "100%",
            overflowX: "unset",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header from={"contactUs"} />
          {/* </Container>
      <Container maxWidth="xl" style={{ height: "100%", overflowX: "unset" }}> */}
          <Grid className={classes.formWrapper}>
            <Typography
              variant="h3"
              fontSize={isMobile && "32px"}
              margin={"8px 0"}
            >
              Send us a message
            </Typography>
            <Typography variant="h4" fontSize={isMobile && "16px"}>
              Submit your queries here and we will get back to you as soon as
              posible
            </Typography>
            <form
              ref={form}
              onSubmit={sendEmail}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <Typography variant="h5" style={{ marginBottom: "25px" }}>
                Personal information
              </Typography>
              <Grid display={"flex"} width={isMobile ? "100%" : "50%"}>
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="fullName"
                  label="Full name"
                  name="fullname"
                  variant="standard"
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                gap={isMobile ? "0" : "25px"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="email"
                  name="email"
                  label="Email"
                  variant="standard"
                />
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="phone"
                  name="phone"
                  label="Phone"
                  variant="standard"
                />
              </Grid>
              <Typography
                variant="h5"
                style={{ marginBottom: "25px", marginTop: "100px" }}
              >
                Business information
              </Typography>
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                gap={isMobile ? "0" : "25px"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="companyName"
                  name="companyName"
                  label="Company name"
                  variant="standard"
                />
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="website"
                  name="website"
                  label="Website"
                  variant="standard"
                />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                gap={isMobile ? "0" : "25px"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="industry"
                  name="industry"
                  label="Industry"
                  variant="standard"
                />
                <TextField
                  className={classes.inputField}
                  color="custom"
                  id="country"
                  name="country"
                  label="Country"
                  variant="standard"
                />
              </Grid>

              <Typography
                variant="h5"
                style={{ marginBottom: "25px", marginTop: "100px" }}
              >
                Your message
              </Typography>
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                gap={"25px"}
              >
                <TextareaAutosize
                  name="message"
                  aria-label="empty textarea"
                  className={classes.textArea}
                  minRows={2}
                />
              </Grid>
              <Grid display={"flex"} marginTop={"50px"}>
                <Button type="submit" className={classes.btn}>
                  {" "}
                  Send{" "}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Container>
      </div>
      <div
        className={classes.main}
        style={{ backgroundImage: "none", backgroundColor: "#fff" }}
      >
        <Container
          container
          maxWidth="xl"
          style={{
            height: "100%",
            overflowX: "unset",
            display: "flex",
            flexDirection: "column",
            marginBottom: "70px",
          }}
        >
          <div position="relative" width={"100%"} height={"100%"}>
            {showParticles && !isMobile && (
              <Particles
                style={{
                  position: "absolute",
                  right: "900px",
                  top: "1500px",
                  zIndex: "999",
                }}
                height="95%"
                width="95%"
                params={{
                  particles: {
                    color: {
                      value: "#8ECAE5",
                    },
                    line_linked: {
                      color: {
                        value: "#B6FBFF75",
                      },
                    },
                    number: {
                      value: 150,
                    },
                    size: {
                      value: 5,
                    },
                  },
                }}
              />
            )}
          </div>
        </Container>
      </div>
      <HomeFullItem
        from="contactUs"
        title="Join our team"
        subtitle="It's easy, send your CV to support@rixsus.com"
      />
      <Footer />
    </div>
  );
};

export default ContactUs;
