import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import Home from "./components/Home";
import OurCompany from "./components/OurCompany";
import ContactUs from "./components/ContactUs";
import Policy from "./components/Policy";

const customTheme = createTheme({
  typography: {
    fontFamily: ["Rubik", "sans-serif"].join(","),
    h1: {
      fontSize: "55px",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "65px",
      color: "#000000",
    },
    h2: {
      fontSize: "55px",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "65px",
      color: "#00B1D9",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "36px",
      lineHeight: "43px",
      color: "#000000",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "22px",
      lineHeight: "26px",
      color: "#505050",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      color: "#00B1D9",
    },
    h6: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "right",
      textTransform: "capitalize",
      color: "#505050",
    },
    body1: {
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#00B1D9",
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#505050",
    },
    body1Mobile: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "35px",
      lineHeight: "36px",
    },
    subTitleMobile: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "36px",
    },
    accordionHeader: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "19px",
      color: "#000000",
    },
  },
  palette: {
    primary: {
      main: "#06AFD6",
    },
    secondary: {
      main: "#FFFFFF",
    },
    custom: {
      main: "#505050",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        {/* <Layout> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/our-company" component={OurCompany} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/privacy-policy" component={Policy} />
        </Switch>
        {/* </Layout> */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
