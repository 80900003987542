import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "../Card";
import Navbar from "../Navbar";
import mainImage from "../../img/main-background.png";
import Header from "../Header";
import HomeItem from "../HomeItem";
import homeMapImg from "../../img/home-map.svg";
import homeOffice from "../../img/office-home.svg";
import Particles from "react-particles-js";
import { useEffect, useMemo, useState } from "react";
import HomeFullItem from "../HomeFullItem";
import Footer from "../Footer";
import useMobileHook from "../Hooks";
import { hotjar } from "react-hotjar";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    margin: "20px 0px",
    boxShadow: "none",
  },
  main: {
    backgroundImage: `url(${mainImage})`,
    /* backgroundImage: `url(
      ${image}
    )`, */
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${mainImage})`,
      backgroundColor: "transparent",
      backgroundSize: "initial",
      paddingBottom: "25px",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  cardWrapper: {
    position: "absolute",
    margin: "-50px -150px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      margin: "20px 0",
    },
  },
  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "999999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  middleContainer: {
    marginTop: "250px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "400px",
    },
  },
}));
const Home = () => {
  const isMobile = useMobileHook();

  const classes = useStyles();
  const [showParticles, setShowParticles] = useState(false);

  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    console.log("Scrolling...", window.scrollY);
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    hotjar.initialize(2787079);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);
    setTimeout(() => {
      setShowParticles(true);
    }, 500);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  console.log("useMobileHook", isMobile);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <div>
        <Container maxWidth="xl">
          <Header from={"home"} />
        </Container>
        <Container maxWidth="xl" className={classes.middleContainer}>
          {isMobile && (
            <Grid item marginBottom={"20px"}>
              <Typography
                variant="h3"
                fontSize={"32px"}
                style={{ marginTop: "10px", textAlign: "center" }}
              >
                Growth your business.
              </Typography>
              <Typography
                variant="h4"
                style={{
                  marginTop: "20px",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                {" "}
                We provide full crossborder payment
              </Typography>
              <Typography
                variant="h4"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                solution for Latam.
              </Typography>
            </Grid>
          )}
          <Grid
            container
            justifyContent={isMobile ? "center" : "space-between"}
          >
            {!isMobile && <Grid item></Grid>}

            <Grid>
              <CustomCard
                title="Alternative payment methods (APM)"
                body="An APM is a payment approach that gives added incentive payments to provide high-quality and cost-efficient care based in  location and users preferences."
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent={isMobile ? "center" : "space-between"}
          >
            {!isMobile && (
              <Grid item>
                <Typography
                  variant={"h3"}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  Growth your business.
                </Typography>
                <Typography
                  variant="h4"
                  style={{ marginTop: "20px", fontSize: "20px" }}
                >
                  {" "}
                  We provide full crossborder payment
                </Typography>
                <Typography variant="h4" style={{ fontSize: "20px" }}>
                  solution for Latam.
                </Typography>
              </Grid>
            )}
            <Grid item>
              <div className={classes.cardWrapper}>
                <CustomCard
                  title="Bank transfers"
                  body="One of the most popular payments methods in Latam is the local bank transfers and we offer instant"
                />
              </div>
            </Grid>
            {!isMobile && <Grid item></Grid>}
            {!isMobile && <Grid item></Grid>}
          </Grid>
          <Grid
            container
            justifyContent={isMobile ? "center" : "space-between"}
          >
            {!isMobile && <Grid item></Grid>}
            {!isMobile && <Grid item></Grid>}
            <Grid>
              <CustomCard
                title="Payouts"
                body="Local solutions and payouts automatization to develop and build local relationship with your customers."
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      {!isMobile && (
        <div position="relative">
          {showParticles && (
            <Particles
              style={{
                position: "absolute",
                right: "900px",
                top: "2000px",
                zIndex: "999",
              }}
              height="95%"
              width="95%"
              params={{
                particles: {
                  color: {
                    value: "#8ECAE5",
                  },
                  line_linked: {
                    color: {
                      value: "#B6FBFF75",
                    },
                  },
                  number: {
                    value: 150,
                  },
                  size: {
                    value: 5,
                  },
                },
              }}
            />
          )}
        </div>
      )}
      <div
        className={classes.main}
        style={{ backgroundImage: "none", backgroundColor: "#F5F5F5" }}
      >
        {/* <Container
          container
          maxWidth="xl"
          style={{
            height: "100%",
            overflowX: "unset",
            display: "flex",
            flexDirection: "column",
            marginBottom: "70px",
          }}
        > */}
        <HomeItem
          padding="40px 0 "
          background="#F5F5F5"
          imagePosition="left"
          image={homeMapImg}
          title="Access to new markets"
          bodyFirstPart="Launch a new market is a Challenge."
          bodySecondPart="Our main objective is build the best solutions for you."
        />
        {/* </Container> */}
        <div
          className={classes.main}
          style={{
            backgroundImage: "none",
            backgroundColor: "#FFF",
          }}
        >
          {/* <Container
            container
            maxWidth="xl"
            style={{
              height: "100%",
              overflowX: "unset",
              display: "flex",
              flexDirection: "column",
              marginBottom: "70px",
            }}
          > */}
          <HomeItem
            image={homeOffice}
            //background={isMobile ? "#F5F5F5" : "#FFF"}
            title="Transparency on each step."
            bodyFirstPart="Understanding the rules, regulations, laws and all the points"
            bodySecondPart=" that involve your business in a new region is the key of success."
          />
          {/* </Container> */}
        </div>
      </div>
      <HomeFullItem />
      <Footer />
    </div>
  );
};

export default Home;
