import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 44px rgba(0, 0, 0, 0.15)",
    borderTopLeftRadius: "20px !important",
    borderBottomRightRadius: "20px !important",
    padding: "30px 20px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  btn: {
    border: "1px solid #00B1D9 !important   ",
    borderTopLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    backgroundColor: "#ffffff !important",
    padding: "10px !important",
    width: "120px",
    zIndex: "99999",
  },
}));

const CustomCard = ({ title, body, withBtn = true }) => {
  const isMobile = useMobileHook();
  const classes = useStyles();
  return (
    <Card
      sx={{
        ...(isMobile
          ? { maxWidth: "100%" }
          : { maxWidth: 315, minHeight: 280 }),
      }}
      className={classes.root}
    >
      <CardContent>
        <Typography variant="h5" component="div" marginBottom="20px">
          {title}
        </Typography>

        <Typography variant="body2">{body}</Typography>
      </CardContent>
      {withBtn && (
        <CardActions style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          ></div>
        </CardActions>
      )}
    </Card>
  );
};

export default CustomCard;
