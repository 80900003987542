import rixsusDesktop from "../../img/rixsusDesktop.svg";
import imgBg from "../../img/tac-background.png";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../Navbar";
import Footer from "../Footer";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    alignItems: "center",
  },

  titleSection: {
    //width: "1190px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0 70px 0",
  },
  titleSubSection: {
    width: "100%",
    display: "flex",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  mainTitle: {
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "36px",
    lineHeight: "65px",
  },
  secondaryTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#505050",
    textAlign: "left",
  },

  subContainer: {
    //width: "1190px",
    height: "100%",
    background: "#ffffff",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 44px rgba(104, 72, 72, 0.4)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
  },

  dot: {
    height: "15px",
    width: "15px",
    backgroundColor: "#000",
    borderRadius: "50%",
    display: "inline-block",
  },
  dotMin: {
    height: "5px",
    width: "5px",
    margin: "0 5px",
    backgroundColor: "#585858",
    borderRadius: "50%",
    display: "inline-block",
  },

  //aca
  main: {
    backgroundImage: `url(${imgBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${imgBg})`,
      backgroundColor: "transparent",
      backgroundSize: "initial",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  accordion: {
    padding: "20px",
    margin: "0 !important",
  },

  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "9999999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  subheader: {
    textAlign: "left",
    fontStyle: "normal !important",
    fontWeight: "normal !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
    color: "#505050 !important",
    marginTop: "10px !important",
  },
  subheaderWrapper: {
    paddingTop: "5px",
    width: "670px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const Policy = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMobileHook();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [header, setHeader] = useState("header");
  const [showParticles, setShowParticles] = useState(false);

  const listenScrollEvent = (event) => {
    console.log("Scrolling...", window.scrollY);
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);
    setTimeout(() => {
      setShowParticles(true);
    }, 500);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <Container
        container
        maxWidth="xl"
        style={{
          height: "100%",
          overflowX: "unset",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.titleSection}>
          <div className={classes.titleSubSection}>
            <Typography variant={isMobile ? "subTitleMobile" : "h3"}>
              Terms & Conditions &nbsp;
              {!isMobile && <span className={classes.dot} />}
            </Typography>

            <Typography variant={isMobile ? "subTitleMobile" : "h3"}>
              {!isMobile && <span style={{ marginRight: "5px" }}>&nbsp;</span>}
              Privacy Policy
            </Typography>
          </div>
          <div className={classes.subheaderWrapper}>
            <Typography className={classes.subheader}>
              This page is meant to help you understand what information we
              collect, why we collect it, and how you can update, manage,
              export, and delete your information.
            </Typography>
          </div>
        </div>
        <div className={classes.subContainer}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className={classes.noWrap}
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Introduction
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" fontWeight={500}>
                RIXSUS is a commercial name, which represents legal entities
                legally registered in the territory of the Federal Republic of
                Brazil: PROPAY PAGAMENTOS LTDA, and in the territory of the
                Argentine Republic: SZFRARDO SERVICIOS DIGITALES S.A.
              </Typography>
              <br></br>
              <Typography variant="body2">
                These Terms and Conditions and the Privacy Policy indicate how
                your personal data is treated as a result of your use of the
                Services in connection with the processing of payments related
                to purchases made by you on our clients' websites. This document
                is an integral part of the RIXSUS Terms and Conditions, which
                contain an overview of the Services. By using our Services, you
                accept our Policy and authorize us to process your personal data
                as described in this document in order to provide our Services
                appropriately.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            className={classes.accordion}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Services
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                RIXSUS (hereinafter the "Service"), consists of the
                implementation of an electronic payment button on the Website or
                Application ("App") of the Clients, who are owners of those
                entities attached to the Service, which allows their users Make
                the payment of the concepts that must be paid, immediately after
                consulting your ballot on the aforementioned page. After the
                implementation of said tool, those who will be in charge of the
                monetary income with the accounts of the Service in Brazil will
                be the BS2 and BEXS banks, and in Argentina it will be Banco
                Galicia S.A. (hereinafter the "Bank").
              </Typography>
              <br></br>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                The Service prohibits the copying, duplication, redistribution,
                commercialization or any other activity that can be carried out
                with the contents of the same, even citing the sources.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            className={classes.accordion}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Registration
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Any user who wishes to use the Service (hereinafter "User") must
                be legally capable to do so and accept these terms and
                conditions. To this end, you must enter the information required
                by the System with valid data and exact, precise and true
                personal information ("Personal Data"), the means of payment
                chosen among those enabled to carry out the transaction, and
                your email address particular (hereinafter "e-mail"). The entry
                of these data in the System by a User, implies the previous
                complete reading, understanding and acceptance without any
                reservation on their part of these terms and conditions. RIXSUS
                reserves the right to refuse access to the system or to cancel
                or suspend, temporarily or permanently, a User, in case of
                detecting incongruities or inconsistencies in the information
                provided by the same, or in case of detecting suspicious
                activities, without such decision generates for the User rights
                of compensation or compensation.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            className={classes.accordion}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
              className={classes.noWrap}
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Personal data
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ol className={classes.listStylePosition}>
                <Typography variant="body2">
                  Some data that we use to provide you with our Services may be
                  considered personal data, that is, information related to you
                  that, individually or collectively, can identify you. We may
                  use the following data for you to enjoy our Services, as well
                  as to improve them:
                  <br></br>
                  <br></br>
                  <li>
                    Identity data: this includes information about your
                    identity, such as your full name, tax identification number,
                    date of birth, address, email, mother's name and telephone
                    number.
                  </li>
                  <li>
                    Financial data: includes information on payment details,
                    banking information and information on the payment methods
                    used by you to buy from electronic businesses that are our
                    clients. The User assumes the commitment to update personal
                    data as necessary. The Bank is not responsible for the
                    accuracy of the personal data provided by its Users. Users
                    guarantee and respond, in any case, for the veracity,
                    accuracy, integrity, validity and authenticity of the
                    personal data entered. All the information entered by the
                    User has the character of an affidavit. RIXSUS reserves the
                    right to request receipts and / or additional information in
                    order to corroborate the information provided by a User
                    regarding personal data, as well as to temporarily or
                    permanently suspend those Users whose data could not be
                    confirmed. RIXSUS carries out the comprehensive protection
                    of the personal data registered in its databases in
                    accordance with the provisions of the General Law for the
                    Protection of Personal Data (LGPD) N°13.709 regulated by
                    Brazil, and in the Personal Data Protection Law N°25.326 ,
                    regulated in Argentina. This data will be used to provide
                    the Service. All personal information transmitted will be
                    made through a secure Internet page that protects and
                    encrypts the information. The User gives his express, free
                    and informed consent so that:
                    <ol className={classes.listStylePosition} type="a">
                      <br></br>
                      <br></br>

                      <li>
                        Your personal data are processed by RIXSUS for the
                        provision of the Service, for statistical, commercial
                        and control purposes, and they may be incorporated into
                        the RIXSUS systems and databases registered with the
                        Access to Public Information Agency
                      </li>
                      <li>
                        RIXSUS transfers or assigns your personal data to
                        controlling or controlled bodies, even free of charge,
                        maintaining their confidentiality and security, for
                        statistical, control or credit rating purposes. Users
                        know and accept that RIXSUS will make its best efforts
                        to maintain the confidentiality and security that this
                        section deals with, but will not be liable for damages
                        that may arise from the violation of said measures by
                        third parties that use public networks or the internet
                        to access information or in cases where there has been
                        fault or User negligence. In the Argentine Republic, the
                        User is aware that in his capacity as the owner of
                        personal data he has the power to exercise the right of
                        access to them free of charge at intervals of not less
                        than six (6) months, unless a legitimate interest for
                        this purpose, and that you have the right to request
                        from RIXSUS the deletion, rectification or updating of
                        erroneous data, if this is appropriate (conf. Arts. 14,
                        Inc. 3 and 16 Law 25.326). The Access to Public
                        Information Agency, Control Body of Law No. 25,326, has
                        the power to deal with complaints and claims that are
                        filed in relation to non-compliance with the rules on
                        personal data protection. Users may at any given time
                        request the alteration or deletion of its personal data
                        from RIXSUS system base, which may refuse the exclusion
                        in cases where the law requires/allows the storage of
                        personal data.
                      </li>
                    </ol>
                  </li>
                </Typography>
              </ol>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            className={classes.accordion}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
              className={classes.noWrap}
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Means of payment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Currency Brazilian reais and Argentinian pesos. Voucher. The
                means of payment enabled by the Service are: Argentina: debit
                cards issued by any bank in the Argentine Republic, Bank
                transfers. Brazil: Boleto Bancario (voucher payment) and PIX In
                order for the System to comply with the payment instructions
                given by the Users, they must have sufficient funds in their
                bank account. All payments will be processed in the legal tender
                of the Brazil territory and Argentina Republic. In the event
                that the payment of invoices and / or services in foreign
                currency is enabled, the exchange rate that will be taken into
                consideration for the conversion of US dollars to pesos will be
                the selling exchange rate published at the close of the previous
                day by the Bank of the Argentine Nation and Bank of Brazil. Once
                the payment has been made, the System will inform the User by
                means of an immediate message of the result of the transaction
                carried out. If the payment is accepted, the User will have the
                corresponding receipt in his or her e-mail.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            className={classes.accordion}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
                className={classes.noWrap}
              >
                Proof of order taken
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                The User accepts the proof of the existence of the payment
                orders issued that arise from the elements that make up the
                System, as well as any proof that is capable of accrediting the
                order issued. To this end, it empowers RIXSUS to use any
                suitable system to prove the existence of the order issued and
                the terms thereof, expressly and irrevocably waiving to question
                the suitability or ability of that test, considering this as an
                essential condition of entry to the System.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            className={classes.accordion}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
                className={classes.noWrap}
              >
                Limitation of liability for the Service
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                RIXSUS does not guarantee continued or uninterrupted access and
                use of the Service. The system that it provides may eventually
                be unavailable due to technical difficulties or internet
                failures, the Website or App owned by those entities that adhere
                to the Service, or due to any other circumstance beyond RIXSUS.
                Users may not impute any responsibility to RIXSUS, nor demand
                any compensation, by virtue of damages resulting from the
                aforementioned difficulties, as well as for any other type of
                damage, including indirect, special or consequential damages
                that arise or experience the Users, even in the event that said
                failures affect the amounts that must be paid.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            className={classes.accordion}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8bh-content"
              id="panel8bh-header"
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Claims
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                The User accepts without reservation, directing any query and /
                or claim that may arise due to or on the occasion of the use of
                the Service, to the entity that is the owner of the Website or
                App where the corresponding payment button was provided, and in
                no case to RIXSUS.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            className={classes.accordion}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel9bh-header"
              className={classes.noWrap}
            >
              <Typography
                sx={{ width: "33%", flexShrink: 0 }}
                variant="accordionHeader"
              >
                Applicable regulations
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                The Service, and the payments made through it, are subject to
                the rules of the Central Bank of Brazil and the Central Bank of
                the Argentine Republic.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
export default Policy;
