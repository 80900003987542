import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    borderLeftWidth: "5px !important",
    boxShadow: "none !important",
    borderLeft: "1px solid transparent",
    borderLeftColor: "#06AFD6 !important",
    padding: "0px 20px",
    width: "100%",
  },
}));

const SemiCard = ({ title, body, bodyBold = false }) => {
  const classes = useStyles();
  return (
    <Card sx={{ maxWidth: 600 }} className={classes.root}>
      <CardContent>
        <Typography
          variant="h4"
          fontSize="18px"
          fontWeight={bodyBold ? "400" : "500"}
        >
          {title}
          <span
            style={{ fontSize: "18px", fontWeight: bodyBold ? "500" : "400" }}
          >
            {" "}
            {body}
          </span>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SemiCard;
